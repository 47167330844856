<template>
    <div class="d-block">
        <button @click="newComment.editable = !newComment.editable" class="btn btn-link">{{ $t("Add action/comment") }}</button>
        <div v-if="newComment.editable" class="form-group">
            <label>{{ $t("Comment") }}</label>
            <input v-model="newComment.comment" class="w-100 form-control form-control-sm m-0" type="text">
        </div>
        <button v-if="newComment.editable" @click="newComment.createComment()" class="btn btn-sm btn-primary p-1 py-0">{{ $t("Save") }}</button>
        <button v-if="newComment.editable" @click="newComment.editable = false; newComment.comment = null" class="ms-1 btn btn-sm btn-secondary p-1 py-0">{{ $t("Cancel") }}</button>
    </div>    
    <div v-for="row in props.dataObject.data.filter(x => x.ParentComment_ID === null)">
        <Comment :data-object="props.dataObject" :createNew="props.createNew" :index="row.index"></Comment>
    </div>
</template>
<script setup lang="ts">
    import { Ref, ref } from "vue";
    import DataObject from "o365.vue.ts";
    import Comment from "o365.vue.components.Comment.vue"

    const props = defineProps<{
        dataObject: DataObject
        createNew?: object
    }>();

    //props.dataObject.createNewAtTheEnd = true;

    class Box {
        public editable: boolean = false;
        public comment: string = null;

        async createComment() {
            props.createNew["Comment"] = this.comment;

            await props.dataObject.createNew(props.createNew)
            this.editable = false;
        }
    }

    const newComment:Ref = ref(new Box());
</script>